import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Jared is building stuff</h1>
            </header>
            <div className="content">
                <p>This site was built with React and Gatsby. 
                    Everything else was built with with love and care</p>
                {/* <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Get Started</a></li>
                </ul> */}
            </div>
        </div>
    </section>
)

export default Banner
