import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import drupalbg from '../assets/images/drupalbg.jpg'
import jaredcode from '../assets/images/jaredcode.png'
import arsticker from '../assets/images/gensticker.png'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Jared Rounsavall"
          meta={[
            { name: 'description', content: 'Sample' },
            {
              name: 'keywords',
              content: 'aws, mobile, iot, web, development, augmented, reality',
            },
          ]}
        />

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${jaredcode})` }}>
              <header className="major">
                <h3>Rounsavall Web Development LLC</h3>
                <p>Freelance and Side Projects</p>
              </header>
              <Link to="/rwd" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${arsticker})` }}>
              <header className="major">
                <h3>AR Stickers</h3>
                <p>Generating Patterns for AR Apps</p>
              </header>
              <Link to="/arstickers" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${drupalbg})` }}>
              <header className="major">
                <h3>Apps & Design</h3>
                <p>Arizona State University</p>
              </header>
              <Link to="/asuappdes" className="link primary" />
            </article>
            <article style={{ backgroundImage: `url(${arsticker})` }}>
              <header className="major">
                <h3>Mobile & IoT</h3>
                <p>Arizona State University</p>
              </header>
              <Link to="/asumobiot" className="link primary" />
            </article>
            {/*
                        <article style={{backgroundImage: `url(${pic02})`}}>
                            <header className="major">
                                <h3>Tempus</h3>
                                <p>feugiat amet tempus</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Magna</h3>
                                <p>Lorem etiam nullam</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic04})`}}>
                            <header className="major">
                                <h3>Ipsum</h3>
                                <p>Nisl sed aliquam</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3>Consequat</h3>
                                <p>Ipsum dolor sit amet</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>Etiam</h3>
                                <p>Feugiat amet tempus</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article> */}
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>How I Operate</h2>
              </header>
              <p>
                I am a lifelong learner with an addiction to engineering
                innovative apps and technologies.
              </p>
              <p>
                Business-minded and goal-oriented, I thrive in a fast-paced
                setting where learning, development and delivery are all
                achieved through high levels of team collaboration. My favorite
                work environment includes an empowered, self-sufficient, team
                that knows enough to work together effectively on projects
                involving new and innovative tech even without years of direct
                experience.
              </p>
              <p>
                <blockquote>
                  "If you know the way broadly you will see it in all things." -
                  Miyamoto Musashi
                </blockquote>
              </p>
              <p>
                High-intensity development can be a double edged sword as
                environements that tilt heavily towards silos or slow movement
                can inflict heavy burnout and bring out the worst in developers.
                For these reasons I am a huge proponent of regular reviews,
                tech-oriented team buildings and lots of allocated professional
                development.
              </p>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
